<template>
  <div v-if="getBalance[currency] && getCurrency[currency]">
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="flex flex-col items-center justify-start flex-grow w-full text-gray-300"
      >
        <div class="max-w-2xl w-full p-7">
          <div>
            <a
              v-on:click="$router.go(-1)"
              class="flex items-center space-x-2 text-center cursor-pointer"
              ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
            >
          </div>
        </div>
      </div>

      <div
        class="mx-auto max-w-xl flex-col items-center justify-start flex-grow w-full p-3"
      >
        <div
          class="inline-block w-full mt-1 p-px bg-gradient-to-r from-blue-800 via-gray-500 to-green-700 rounded"
        >
          <div
            class="inline-block w-full text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded bg-gray-900 bg-opacity-75"
          >
            <div class="flex flex-col sm:flex-row px-8 py-3 sm:py-6">
              <div class="flex flex-grow items-center py-3 sm:py-0">
                <b v-if="getCurrency[currency].img_pair == true">
                  <img
                    class="h-8 inline mr-1"
                    :src="getCurrency[currency].logo"
                  />

                  <img
                    class="h-8 inline mr-1 mt-1"
                    :src="getCurrency[currency].logo2"
                  />
                </b>

                <b v-else>
                  <img
                    class="h-8 inline mr-2"
                    :src="getCurrency[currency].logo"
                  />
                </b>

                <!-- <img class="h-8 mr-2" :src="getCurrency[currency].logo" /> -->
                <span
                  class="text-2xl font-medium text-transparent bg-clip-text bg-gradient-to-r from-blue-300 to-pink-400"
                >
                  {{ getCurrency[currency].name }}</span
                >
              </div>

              <div class="flex-none sm:mt-2">
                <div
                  class="inline text-xs px-2 py-1 mr-2 border rounded-full text-yellow-50 border-yellow-50 cursor-pointer opacity-80 hover:opacity-100 focus:opacity-100"
                >
                  <a
                    class="text-blue opacity-80 hover:opacity-100 focus:opacity-100 text-white"
                    v-bind:href="getCurrency[currency].website"
                    target="_blank"
                  >
                    <i class="fa fa-globe"></i> Website
                  </a>
                </div>

                <a
                  class="inline opacity-80 hover:opacity-100 focus:opacity-100"
                  v-bind:href="`
                    https://${explorer}/token/${getCurrency[currency].address[chain].address}?a=${getUser.address}`"
                  target="_blank"
                >
                  <img
                    class="inline-block h-5"
                    :src="require(`@/assets/chain/bscscan.png`)"
                  />
                </a>

                <div
                  class="inline text-xs px-2 py-1 ml-2 border rounded-full text-yellow-50 border-yellow-50 cursor-pointer opacity-80 hover:opacity-100 focus:opacity-100"
                  v-on:click="
                    addToken({
                      c: currency,
                      ca: getCurrency[currency].address[chain].address,
                      decimals: getCurrency[currency].address[chain].decimals,
                      logo: getCurrency[currency].logo,
                    })
                  "
                >
                  Add to
                  <img
                    src="@/assets/svg/metamask.svg"
                    class="inline-block ml-1 h-5"
                  />
                </div>
              </div>
            </div>

            <div class="px-8 py-3 sm:py-6">
              <div class="text-gray-200">
                <div class="flex flex-col">
                  <div>
                    <div class="flex font-semibold mt-2">
                      <span
                        class="flex-grow leading-tight text-gray-200 sm:text-base"
                        style="font-size: 12px"
                      >
                        Available Deposit
                      </span>
                      <span
                        class="flex-none leading-tight whitespace-nowrap text-shadow-white text-xs sm:text-base"
                        ><span style="font-size: 14px">
                          {{ tokenBalance }} {{ currency }}</span
                        ></span
                      >
                    </div>

                    <div class="mt-2">
                      <div
                        class="flex justify-center py-1 sm:py-3 px-3 rounded-xl bg-gray-700"
                      >
                        <input
                          id="amount"
                          v-model="deposit_amount"
                          placeholder="0"
                          class="flex-grow outline-none text-sm bg-transparent"
                          type="number"
                          min="0"
                          step="0.00000001"
                        /><span
                          class="w-20 flex-none text-sm px-6 mt-1 rounded text-gray-400 cursor-pointer hover:text-yellow-200"
                        >
                          MAX
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <form @submit.prevent="deposit">
                  <div class="flex justify-center py-3">
                    <button
                      class="w-full flex-none text-sm px-6 py-2 border rounded-xl text-yellow-50 border-none bg-gradient-to-r from-blue-700 to-green-600"
                      v-if="loading"
                      disabled
                    >
                      Loading
                    </button>
                    <button
                      class="w-full flex-none text-sm px-6 py-2 border rounded-xl text-yellow-50 border-none bg-gradient-to-r from-blue-700 to-green-600"
                      disabled
                      v-else-if="!deposit_amount"
                    >
                      Deposit
                    </button>
                    <button
                      class="w-full flex-none text-sm px-6 py-2 border rounded-xl text-yellow-50 border-none bg-gradient-to-r from-blue-700 to-green-600"
                      v-else
                    >
                      Deposit
                    </button>
                  </div>
                </form>

                <div class="grid grid-cols-1 gap-y-3 mt-10">
                  <div class="flex flex-col space-y-8 space-y-0">
                    <div>
                      <div class="flex font-semibold mt-2">
                        <span
                          class="flex-grow leading-tight text-gray-500 sm:text-base"
                          style="font-size: 12px"
                        >
                          Available Withdrawal
                        </span>
                        <span
                          class="flex-none leading-tight whitespace-nowrap text-shadow-white text-xs sm:text-base"
                          ><span style="font-size: 14px">
                            {{ getBalance[currency].available }}
                            {{ currency }}</span
                          ></span
                        >
                      </div>

                      <div class="mt-2">
                        <div
                          class="flex justify-center py-1 sm:py-3 px-3 rounded-xl bg-gray-700"
                        >
                          <input
                            id="amount"
                            v-model="withdrawal_amount"
                            placeholder="0"
                            class="flex-grow outline-none text-sm bg-transparent"
                            type="number"
                            min="0"
                            step="0.00000001"
                          /><span
                            class="w-20 flex-none text-sm px-6 mt-1 rounded text-gray-400 cursor-pointer hover:text-yellow-200"
                          >
                            MAX
                          </span>
                        </div>
                      </div>
                      <div class="flex justify-center py-3">
                        <button
                          @click="withdrawal()"
                          class="w-full flex-none text-sm px-6 py-2 border rounded-xl text-yellow-50 border-none bg-gradient-to-r from-blue-700 to-red-600"
                        >
                          Withdrawal
                        </button>
                      </div>

                      <span
                        class="grid grid-cols-1 lg:grid-cols-2 sm:flex-row p-2 border rounded-lg mb-2 border-opacity-30 text-xs sm:text-base"
                      >
                        <span>
                          <img
                            class="inline-block h-5 mr-2"
                            :src="require(`@/assets/chain/${chain}.png`)"
                          />
                          Binance Smart Chain</span
                        >
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="currency == 'BNB'"
        class="flex justify-center text-sm text-gray-100 mt-10"
      >
        Swap BNB to USDT auto, Last price from binance
      </div>

      <br />
      <br />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import func from "@/widgets/func";
import user from "@/api/user";
import LiveTx from "@/components/LiveTx.vue";
import {
  POOL_ADDRESS,
  SITE_URL,
  CHAIN,
  EXPLORER,
  CHAIN_FULL_NAME,
} from "../../config";
import nativeToast from "native-toast";

import Web3 from "web3";

import ConnectWallet from "@/components/ConnectWallet.vue";

const tokenABI = [
  // transfer
  {
    constant: false,
    inputs: [
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        name: "success",
        type: "bool",
      },
    ],

    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    type: "function",
  },
];

export default {
  components: {
    ConnectWallet,
    LiveTx,
  },
  data() {
    return {
      chain: CHAIN.toLowerCase(),
      explorer: EXPLORER,
      chain_full: CHAIN_FULL_NAME,
      currency: this.$route.params.currency,
      accounts: [],
      deposit_amount: 0,
      withdrawal_amount: 0,
      loading: false,
      value: null,
      dara: null,
      to: null,
      contract_address: null,
      tokenBalance: null,
      lasttx: null,
    };
  },
  computed: mapGetters(["getBalance", "getCurrency", "getUser"]),
  methods: {
    async withdrawal() {
      // this.loading = true;

      // if (
      //   this.amount -
      //     this.getCurrency[this.currency].address[this.chain].withdraw_fee / 1 <
      //   0
      // ) {
      //   this.loading = false;
      //   nativeToast({
      //     message: `Minimum withdrawal: ${
      //       (this.getCurrency[this.currency].address[this.chain].withdraw_fee *
      //         2) /
      //       1
      //     } ${this.currency}`,
      //     position: "top",
      //     timeout: 3000,
      //     type: "error",
      //   });
      //   return;
      // }

      if (
        parseFloat(this.withdrawal_amount) >
        parseFloat(this.getBalance[this.currency].available)
      ) {
        nativeToast({
          message: `Balance not enough`,
          position: "top",
          timeout: 3000,
          type: "error",
        });
      } else {
        await user
          .withdraw({
            amount: this.withdrawal_amount,
            currency: this.currency,
            chain: this.chain,
          })
          .then((response) => {
            this.loading = false;
            nativeToast({
              message: response.data.message,
              position: "top",
              timeout: 10000,
              type: "success",
            });
            this.withdrawal_amount = null;
          })
          .catch((error) => {
            this.loading = false;
            nativeToast({
              message: error.response.data.error,
              position: "top",
              timeout: 3000,
              type: "error",
            });
          });
      }
    },
    deposit() {
      this.loading = true;
      if (this.tokenBalance / 1 < this.deposit_amount / 1) {
        this.loading = false;
        nativeToast({
          message: `The amount you entered is more than your available balance.( You have ${this.tokenBalance} ${this.currency})`,
          position: "top",
          timeout: 3000,
          type: "error",
        });
        return;
      }
      const web3 = new Web3(ethereum);

      if (
        (this.currency == "BNB" && this.chain == "bsc") ||
        (this.currency == "ETH" && this.chain == "eth") ||
        (this.currency == "MATIC" && this.chain == "polygon")
      ) {
        this.to = POOL_ADDRESS; // pool address
        this.value =
          "0x" + (this.deposit_amount * Math.pow(10, 18)).toString(16);
        this.data = "0x";
      } else {
        var contract = new web3.eth.Contract(tokenABI, this.contract_address);
        this.to = this.contract_address; //contract address
        this.value = "0x0";
        this.data = contract.methods
          .transfer(
            POOL_ADDRESS,
            (this.deposit_amount * 1000000000000000000).toLocaleString(
              "fullwide",
              { useGrouping: false }
            )
          )
          .encodeABI();
      }

      //Sending Ethereum to an address
      ethereum
        .request({
          method: "eth_sendTransaction",
          params: [
            {
              from: this.accounts[0],
              to: this.to,
              value: this.value,
              data: this.data,
            },
          ],
        })
        .then((txHash) => {
          this.loading = false;
          this.lasttx = txHash;
          this.getTokenBalance();

          setTimeout(() => {
            this.getTokenBalance();
          }, 3500);

          nativeToast({
            message: `Tx Submitted: ${txHash.slice(0, 12)}...${txHash.slice(
              -10
            )}`,
            position: "top",
            timeout: 3000,
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          nativeToast({
            message: error.message,
            position: "top",
            timeout: 3000,
            type: "error",
          });
        });
    },
    async getAccount() {
      this.accounts = await ethereum.request({ method: "eth_requestAccounts" });
      this.getTokenBalance();
    },
    async getTokenBalance() {
      const web3 = new Web3(ethereum);

      if (
        (this.currency == "BNB" && this.chain == "bsc") ||
        (this.currency == "ETH" && this.chain == "eth") ||
        (this.currency == "MATIC" && this.chain == "polygon")
      ) {
        const ether_raw = await web3.eth.getBalance(this.accounts[0]);
        this.tokenBalance = (ether_raw / Math.pow(10, 18)).toFixed(8);
      } else {
        const tokenInst = new web3.eth.Contract(
          tokenABI,
          this.contract_address
        );
        const token_raw = await tokenInst.methods
          .balanceOf(this.accounts[0])
          .call();
        this.tokenBalance = (token_raw / Math.pow(10, 18)).toFixed(8);
      }
    },
    amount(a) {
      return func.fixed8(a);
    },
    async addToken(body) {
      const tokenAddress = body.ca;
      const tokenSymbol = body.c;
      const tokenDecimals = body.decimals;
      if (!body.logo) body.logo = `/tokens/BLANK.png`;
      const tokenImage = SITE_URL + body.logo;

      try {
        const wasAdded = await ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20", // Initially only supports ERC20, but eventually more!
            options: {
              address: tokenAddress, // The address that the token is at.
              symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
              decimals: tokenDecimals, // The number of decimals in the token
              image: tokenImage, // A string url of the token logo
            },
          },
        });
      } catch (error) {
        nativeToast({
          message: error.message,
          position: "top",
          timeout: 3000,
          type: "error",
        });
      }
    },
  },
  created() {
    if (this.getCurrency[this.currency].address[this.chain]) {
      this.getAccount();
      this.contract_address =
        this.getCurrency[this.currency].address[this.chain].address;
    }
  },
  updated() {
    this.getTokenBalance();
  },
};
</script>
